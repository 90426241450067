import { Link } from "react-router-dom"
import LoadingPage from "../../pages/LoadingPage"
import { useHospital } from "../../context/Context"
import { Popover } from "antd"

function XorazmMap() {
    const { lang, isLoading, zones, isToken } = useHospital()
    return (
        <>
            {
                !isLoading && zones.length > 0 && isToken
                    ?
                    <div className="region" id="region">
                        <svg xmlns="http://www.w3.org/2000/svg" height="500px" viewBox="0 0 919 659">
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[0][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/bogot-tumani">
                                        <path d="m387.21,294.58s-3.21,9.92 -5.88,11.25s-9.66,0 -9.66,0l3.66,10s-7.5,19 -13.83,26s-10.17,22.32 -10.17,32c-17.59,0.66 -43.17,1.59 -63.58,2.17c-13.18,0.38 -26.23,1.21 -37.74,2.07l-0.08,-1.24c-0.08,-1.24 3.4,-8.33 3.4,-10.83s-2.83,-0.83 -3.33,-3.5s1.67,-6 3.83,-8.33a39,39 0 0 0 7.17,-12.67c2,-5.67 5.83,-14.83 10.67,-18c2.14,-1.4 7,-2 11.67,-2.27c5.92,-0.32 11.66,-0.06 11.66,-0.06a57,57 0 0 1 7.83,-6c3.67,-2.17 10.5,-1.17 23.34,-2.5s17.66,-6.67 17.66,-6.67l1.67,-6.67l-3.5,-7l7,-2s18,0.67 20.27,0s12,-13.22 12,-13.22a6.23,6.23 0 0 1 0.75,1.64a71,71 0 0 1 1.5,9.75l3.69,6.08z" data-name="Боғот тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[7][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/xonqa-tumani">
                                        <path d="m381.25,277.11s-9.71,12.56 -12,13.22s-20.27,0 -20.27,0l-7,2l3.5,7l-1.65,6.67s-4.83,5.33 -17.66,6.67s-19.67,0.33 -23.34,2.5a57,57 0 0 0 -7.83,6s-5.74,-0.26 -11.66,0.06l1.66,-8.06l0.83,-11.67c-6.16,-5.5 -20.33,-5.33 -27,-6.92s-7.5,-5.58 -7.5,-5.58l4.17,-6.33l-5.57,-15.67s8.57,-0.17 15.9,0s18.5,3.17 21,1.5s3.67,-9.67 3.67,-9.67s-7,-5.83 -6.5,-8s30,-1.83 30,-1.83l7.74,-15.42c3.34,3.53 7.66,8.41 8.26,10.67a18.94,18.94 0 0 1 0,8s15.25,6 18,7.75s14.25,13.5 14.25,13.5l6,0l5.5,-3.75s5.36,3.88 7.5,7.36z" data-name="Ҳонқа тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[9][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/yangiariq-tumani">
                                        <path d="m285.83,301.5l-0.83,11.67l-1.66,8.06c-4.71,0.25 -9.53,0.87 -11.67,2.27c-4.84,3.17 -8.67,12.33 -10.67,18a39,39 0 0 1 -7.17,12.67c-2.16,2.33 -4.33,5.66 -3.83,8.33s3.33,1 3.33,3.5s-3.48,9.59 -3.4,10.83l0.08,1.24c-19,1.42 -33.9,2.9 -38.26,2.43c-4.7,-0.5 -14,-5.18 -21.84,-10.09l-0.58,-7.91s4.17,-14.5 4.5,-17.67s3.34,-8.66 3.34,-8.66l4.5,1.5l0,4l3.83,0l1.33,-5.5s-2.33,-1.34 -0.83,-10.84s6,-8.5 8.33,-10.5s8.34,-13.83 8.34,-13.83l24,-12l4.66,0s0.84,4 7.5,5.58s20.84,1.42 27,6.92z" data-name="Янгиариқ тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[5][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/xiva-tumani">
                                        <path d="m255.5,282.67l-4.17,6.33l-4.66,0l-24,12s-6,11.83 -8.34,13.83s-6.83,1 -8.33,10.5s0.83,10.84 0.83,10.84l-1.33,5.5l-3.83,0l0,-4l-4.5,-1.5s-3,5.5 -3.34,8.66s-4.5,17.67 -4.5,17.67l0.58,7.91a84.48,84.48 0 0 1 -9.66,-6.91c-7.25,-6.25 -37.75,-30.75 -51.5,-38.5c-4.64,-2.61 -10.35,-5.48 -15.84,-8.67c0,0 4.59,-8.16 6.26,-10.5s0.83,-9 3,-11.83s9.66,-1.33 9.66,-1.33l6.34,-6.5c3.16,0.16 8.5,9.16 11.83,10.33s8.17,-2.17 11.17,-3.33s12.5,-1.5 12.5,-1.5l1.83,-5.17l-1.83,-3.5l1.16,-4.33l5.34,0.83l9.16,4.17s4,0.16 7.34,0.16s9.16,-2 9.16,-2l-0.16,-3.66l1.83,-4.34l7.67,-0.33l5.16,2.33l6.34,0.34l1.33,-2.5l-4,-3.34l2.83,-4.83l5.17,0l3.5,2.67l14.43,-1.17l5.57,15.67z" data-name="Хива тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[3][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/urganch-tumani">
                                        <path d="m321.74,233l-7.74,16s-29.5,-0.33 -30,1.83s6.5,8 6.5,8s-1.17,8 -3.67,9.67s-13.66,-1.33 -21,-1.5s-15.9,0 -15.9,0l-14.43,1.17l-3.5,-2.67l-5.17,0s-6.16,-3.67 -10.66,-5a60.36,60.36 0 0 0 -11.34,-1.67l-4.33,-5.33l0,-10.5a6.74,6.74 0 0 0 -5,1.33c-2.33,1.84 -4.33,1 -7,-2.16s-1.17,-11.84 -3.17,-13.84l24.77,-22c6,3.24 15.24,2.91 18.4,6.21c3.83,4 9.17,2.83 11.17,2.17s2.33,-9.5 10.26,-10.5s7.24,5.33 8.74,7.16s5.5,-0.16 8.33,-1.83s4,-3.5 4.17,-7s-3,-2.33 -6.74,-3.33s-3.6,-2.34 -1.76,-7.67s-1.34,-5.67 -2.17,-7.83s0.17,-7.17 0,-7.67s6.83,4.33 6.83,4.33c4.67,-3.33 -4,-12.66 -4.5,-12.83s2.17,-4.54 2.17,-4.54l10,5.75s4.25,8.25 4.5,12s0,10.25 0,10.25s2.25,8.75 8.5,11.5a35.3,35.3 0 0 0 11.25,3s0.75,13 4.25,17.5c6.5,8.25 14.25,6.5 14.25,6.5s1.78,1.74 3.99,4.08l0,-0.58z" data-name="Урганч" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[2][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/qoshkopir-tumani">
                                        <path d="m228,273.67l-1.33,2.5l-6.34,-0.34l-5.16,-2.33l-7.67,0.33l-1.83,4.34l0.16,3.66s-5.83,2 -9.16,2s-7.34,-0.16 -7.34,-0.16l-9.16,-4.17l-5.34,-0.83l-1.16,4.33l1.83,3.5l-1.83,5.17s-9.5,0.33 -12.5,1.5s-7.84,4.5 -11.17,3.33s-8.67,-10.17 -11.83,-10.33l-6.34,6.5s-7.5,-1.5 -9.66,1.33s-1.34,9.5 -3,11.83s-6.26,10.5 -6.26,10.5c-10.78,-6.25 -20.65,-13.72 -19.66,-22.83c1.5,-13.75 0.25,-34.75 7,-43s14.25,-8.34 14.25,-16.92c0,-6.28 -3.48,-15.6 -8.88,-24.75c0,0 23.55,-1 27.88,0c9,1.17 24.83,18.84 29.17,20s11.83,0.5 14.66,0s6,-2.5 8,-0.5s0.5,10.67 3.17,13.84s4.67,4 7,2.16a6.74,6.74 0 0 1 5,-1.33l0,10.5l4.33,5.33a60.36,60.36 0 0 1 11.34,1.67c4.5,1.33 10.66,5 10.66,5l-2.83,4.83l4,3.34z" data-name="Қўшкўпир тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[8][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/shovot-tumani">
                                        <path d="m210.1,206.29l-24.77,22c-2,-2 -5.16,0 -8,0.5s-10.33,1.17 -14.66,0s-20.17,-18.83 -29.17,-20c-4.33,-1 -27.88,0 -27.88,0a100,100 0 0 0 -6.62,-9.79c-9,-11.75 -15,-18 -14.75,-27.75c0,0 5,-20.75 8,-22.5s5.25,3.5 9.75,2.75s3.75,-0.5 8,2s9,1.5 14,4.5a82.84,82.84 0 0 1 10.75,8.25l6.75,0l10,5.75l2.25,-3s6.5,2.75 6.25,-3a4.73,4.73 0 0 0 -0.13,-0.94s8.8,-3.23 13.63,-4.56s6.67,4.5 11.83,3.5a3.87,3.87 0 0 0 2,-1s6.54,15 6.54,15.5s10.67,6 10.67,6s-1.33,13 2.33,19.17a10.78,10.78 0 0 0 3.23,2.62z" data-name="Шовот тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[10][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/yangibozor-tumani">
                                        <path d="m271.17,202.5c-0.17,3.5 -1.34,5.33 -4.17,7s-6.83,3.67 -8.33,1.83s-0.82,-8.16 -8.74,-7.16s-8.26,9.83 -10.26,10.5s-7.34,1.83 -11.17,-2.17c-3.16,-3.3 -12.44,-3 -18.4,-6.21a10.78,10.78 0 0 1 -3.27,-2.62c-3.66,-6.17 -2.33,-19.17 -2.33,-19.17s-10.67,-5.5 -10.67,-6s-6.54,-15.5 -6.54,-15.5c2.88,-2.7 2,-9.5 2,-9.5s8.5,-16.33 10.84,-18s22.16,-0.33 22.16,-0.33l4.5,-5l3.34,2.16l-1,8.34c3,6.66 19.08,1.83 19.08,1.83l0,7l-2,5.5l4.5,8l14.29,0s-2.67,4.33 -2.17,4.5s9.17,9.5 4.5,12.83c0,0 -7,-4.83 -6.83,-4.33s-0.84,5.5 0,7.67s4,2.5 2.17,7.83s-2,6.67 1.76,7.67s6.9,-0.17 6.74,3.33z" data-name="Янгибозор тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[1][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/gurlan-tumani">
                                        <path d="m248.25,142.5s-16.08,4.83 -19.08,-1.83l1,-8.34l-3.34,-2.16l-4.5,5s-19.83,-1.34 -22.16,0.33s-10.84,18 -10.84,18s0.84,6.8 -2,9.5a3.87,3.87 0 0 1 -2,1c-5.16,1 -7,-4.83 -11.83,-3.5s-13.63,4.56 -13.63,4.56c-1.18,-5 -9.72,-6.83 -11.37,-11.31c-1.75,-4.75 -9.75,-9.25 -15.75,-10.75s-16.5,-2 -20.25,-9.25s-5.5,-10.75 -5.5,-10.75a24.07,24.07 0 0 1 9.5,-10.08c7,-4.17 11,-6.67 11,-6.67l8,0.25l4.5,5.15l3.75,-5.4l-5.5,-14.56l2.75,-12.69s5.75,0.25 7.5,2.75s3.75,5.25 3.75,5.25l4.75,0a13.19,13.19 0 0 1 4,-5.75c2.75,-2 11.25,-2.25 11.75,-6.5a52.11,52.11 0 0 0 0.25,-8.25l10.5,2.5l0.75,-9l5.5,-1.5s1.75,7.75 2.5,7.75s4.5,6 5.5,6s6,2 7,2l8.75,0l7.5,-4.5s5.75,3.75 6.25,4.5s1.75,12.25 0,19.75c0,0 5.5,11 5.25,14.5a98.6,98.6 0 0 1 -1.75,10.25s8.75,17.75 9.5,17.75s8,6 8,6z" data-name="Гурлан тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[6][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/hazorasp-tumani">
                                        <path d="m466.5,405c-9.5,4.75 -32.75,4.25 -37,2s-17.75,-8.25 -17.75,-8.25s-14.75,-16.75 -18.5,-16.75a118.25,118.25 0 0 0 -12,1.25s-10.25,-10.25 -9.25,-10.25c-0.33,0 -8.63,0.37 -20.67,0.83c0,-9.68 3.84,-25 10.17,-32s13.83,-26 13.83,-26l-3.66,-10s7,1.34 9.66,0s5.88,-11.25 5.88,-11.25l8,13.17l11.75,1.5l12.5,11.5l24.75,8.75s3.5,7.25 4.25,7.5s-4.25,22.5 -4.25,22.5l3.25,8s9.5,5.25 12,6.75s15,10.5 15,10.5s1.54,15.5 -7.96,20.25z" data-name="Ҳазорасп тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[13][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/tuproqqala-tumani">
                                        <path d="m471,373.75c1,0 7,2.25 7,2.25s-1.25,-10 0,-13.5s4.5,-11.25 10.25,-11s13.75,2.5 20.25,5.75s17.75,4 17.75,4s7.25,-5.5 11.75,-3.25c9.25,4.5 9.25,14.75 9.25,14.75s7.25,6 9.75,6l8.25,0s11,15.25 15.75,18.25s10.75,7.25 15.75,7.5c0,0 9.5,11.25 12.25,12.75s7.75,4.75 7.75,4.75s5,8.5 5.5,11.75s-1.5,7.25 -1.5,7.25s2.75,3.5 9,6s16.5,4.25 16.25,24c1.5,0.75 6.25,1.5 5.25,5.75s0.52,9.75 0.14,17.75s1.11,14.5 1.11,21.75s-1.25,16 0,18.5s6.25,10.75 7.75,14.75s4,9.25 4,9.25s-1.25,15.5 2.5,21s8,9 10.75,16.5s6,11.25 6.5,16.75a27.9,27.9 0 0 0 5,15.5l95.25,-74.75l11.75,-31l-62.75,-73.75l-26.5,12.5s-23.5,-24 -40,-42.75s-64.5,-78 -64.5,-78l-17.75,-9.75s-33.25,-3 -45.5,-4.25s-38.25,-3.5 -38.25,-3.5l-23.5,17l-6.25,19l0,14.5z" data-name="Ҳазорасп тумани" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[4][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/urganch-shahri">
                                        <path d="M 265, 240 a 10,10 0 1,1 1,1 a 10,10 0 01,1 0,0" data-name="Urganch shahri" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                            <g className="grouped">
                                <Popover title={zones[12]?.districts[11][`name_${lang}`]}>
                                    <Link to="/xorazm-viloyati/xiva-shahri">
                                        <path d="M 170, 320 a 10,10 0 1,1 1,1 a 10,10 0 01,1 0,0" data-name="Xiva shahri" fill="transparent" stroke="#fff" strokeWidth="2"></path>
                                    </Link>
                                </Popover>
                            </g>
                        </svg>
                    </div >
                    :
                    <LoadingPage />
            }
        </>
    )
}

export default XorazmMap;